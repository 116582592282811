import React from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing marker icon
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});


const geojson_to_plot = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "coordinates": [
          [
            -2.608457197601524,
            51.46134146437228
          ],
          [
            -2.608075134700073,
            51.461337963762475
          ],
          [
            -2.60742338033765,
            51.46143948132976
          ],
          [
            -2.6067660074035928,
            51.46166701988474
          ],
          [
            -2.6059119844467773,
            51.46192606239731
          ],
          [
            -2.6054568801080507,
            51.46209408918665
          ],
          [
            -2.605563632977777,
            51.46246164563121
          ],
          [
            -2.6066255431014724,
            51.46288870749714
          ],
          [
            -2.607316627467924,
            51.46343828123
          ],
          [
            -2.608049055085786,
            51.463974347917315
          ],
          [
            -2.608689572304087,
            51.46430338503063
          ],
          [
            -2.6092626666566616,
            51.464551911490304
          ],
          [
            -2.6095267395442363,
            51.464551911490304
          ],
          [
            -2.6095716881207807,
            51.464474903436525
          ],
          [
            -2.609251429512568,
            51.463666311023275
          ],
          [
            -2.6089480266200553,
            51.46277369237049
          ],
          [
            -2.608711971541254,
            51.46224511749136
          ],
          [
            -2.608588362954862,
            51.461769041556835
          ],
          [
            -2.608543414378346,
            51.461376975293746
          ],
          [
            -2.6084984658018016,
            51.461271956972524
          ]
        ],
        "type": "LineString"
      }
    }
  ]
}

L.Marker.prototype.options.icon = DefaultIcon;

const MapComponent = () => {
  const position = [51.461, -2.6084];  // Latitude and Longitude for the map center

  return (
    <MapContainer center={position} zoom={15} style={{ height: "100vh", width: "100%" }}>
      <TileLayer
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        minZoom={5}
        maxZoom={17}
        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoJSON data={geojson_to_plot} />
    </MapContainer>
  );
};

export default MapComponent;

